<template>
  <b-card title="Self learning">
    <b-tabs>
    <b-tab :active="currentRouteName=='recorded-course'"
     @click="$router.push('/recorded-course')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Self learning Course</span>
        </template>

      </b-tab>
       <b-tab :active="currentRouteName=='recorded-list'"
     @click="$router.push('/recorded-list')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Self learning List</span>
        </template>

      </b-tab>
      <!-- <b-tab :active="currentRouteName=='long-course-content'"
     @click="$router.push('/long-course-content')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Diplomas Content</span>
        </template>

      </b-tab> -->
  
      
     
    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <RecordedCourse v-if="route.name === 'recorded-course' "  />
      <router-view  v-else>
          <component :is="Component" />
        </router-view>
  </router-view>
  
  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'



import RecordedCourse from './RecordedCourse.vue';



export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
  
    RecordedCourse,
  
},
data() {
    return {
   role:{}
    }
  },
  created(){
    this.role=JSON.parse(localStorage.getItem('userData'))
  }
  ,
   computed: {
    currentRouteName() {
        return this.$route.name;
    }}}
</script>
